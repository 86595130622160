import { useCallback, useEffect, useState } from "react";

import { Box, CircularProgress, FormHelperText, Grid } from "@mui/material";
import { getFormFieldsByCountry } from "api/features/Auth/authApi";
import TextFieldInput from "components/molecules/Inputs/TextFieldInput";
import OutlinedSelectField from "components/molecules/OutlinedSelectField";
import { FormField } from "models/form.model";
import ReCaptcha from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { validateRequiredFields } from "utils/validateRequiredFields";

import ButtonFooter from "../../../molecules/ButtonFooter";
import FormWrapper from "../../../templates/FormWrapper";

export interface IFormTwo {
	title?: string;
	firstName: string;
	middleName?: string;
	lastName: string;
	preferredLanguage?: string;
	countryCode?: string;
	collegiateNumber?: string;
	codeRPPS?: string;
	city?: string;
	orderNumber?: string;
	profession?: string;
	specialty?: string;
	recaptchaGoogle: string;
}

const nonValidationFieldsList = ["codeRPPS"];

const DynamicFields = ({ field, control }) => {
	const { t } = useTranslation();

	switch (field.inputType) {
		case "select":
			return (
				<OutlinedSelectField
					required={field.required}
					label={t(`signUpPageHCP.${field.label}`)}
					type={field.inputType}
					placeholder={t(`signUpPageHCP.${field.placeholder}`)}
					name={field.name}
					control={control}
					defaultValue={field.defaultValue}
					rules={{ required: field.required }}
					options={t(`signUpPageHCP.${field.name}.inputOptions`, { returnObjects: true })}
				/>
			);
		case "text":
			return (
				<Controller
					name={field.name}
					control={control}
					defaultValue={field.defaultValue}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextFieldInput
							inputProps={{
								maxLength: 100
							}}
							placeholder={t(`signUpPageHCP.${field.placeholder}`)}
							variant="outlined"
							value={value}
							onChange={onChange}
							label={t(`signUpPageHCP.${field.label}`)}
							error={!!error}
							helperText={error ? error.message : null}
							type="text"
						/>
					)}
					rules={
						!nonValidationFieldsList.includes(field.name)
							? {
									required: field.required,
									minLength: {
										value: 2,
										message: t("dynamicForm.validation.minText")
									},
									maxLength: {
										value: 100,
										message: t("dynamicForm.validation.maxText")
									}
							  }
							: undefined
					}
				/>
			);
		default:
			return null;
	}
};

const FormTwo = ({ submitAllForms, setActiveStep, activeStep, isLoading }) => {
	const { t } = useTranslation();
	const { defaultLanguage } = useParams();

	const [fieldsLoading, setFieldsLoading] = useState(false);

	const { countryId } = useParams();

	const {
		control,
		handleSubmit,
		formState: { errors, isValid, isDirty }
	} = useForm({
		mode: "onChange",
		delayError: 1000,
		defaultValues: {
			title: "",
			firstName: "",
			lastName: "",
			recaptchaGoogle: "",
			primaryPlaceOfWork: ""
		}
	});

	const [fields, setFields] = useState<FormField[]>([]);

	const fetchFields = useCallback(async () => {
		setFieldsLoading(true);
		const apiResponse = await getFormFieldsByCountry(countryId);
		setFields(apiResponse.fields);
		setFieldsLoading(false);
	}, [countryId]);

	useEffect(() => {
		fetchFields();
	}, [fetchFields]);

	const onSubmit = async (data: IFormTwo) => {
		submitAllForms(data);
	};

	const isValidForm = validateRequiredFields(errors);

	return (
		<>
			{!fieldsLoading ? (
				<FormWrapper
					title={t("signUpPageHCP.title")}
					description={t("signUpPageHCP.subtitle")}
					footerBlock={
						<ButtonFooter
							isLoading={isLoading}
							primaryButtonText={t("buttons.signUp")}
							handlePrimaryButton={handleSubmit(onSubmit)}
							activeStep={activeStep}
							disablePrimaryButton={!isDirty || !isValid}
							secondaryButton={true}
							handleSecondaryButton={() => setActiveStep(s => s - 1)}
						/>
					}
					onSubmit={handleSubmit(onSubmit)}
					errorSummary={!isValidForm ? t("signUpPageHCP.error") : ""}
				>
					<Grid container spacing={1}>
						<Grid item xs={12} md={3}>
							<OutlinedSelectField
								label={t("signUpPageHCP.nameTitle.label")}
								type="select"
								placeholder={t("signUpPageHCP.nameTitle.placeholder")}
								name="title"
								control={control}
								defaultValue=""
								options={[
									{ value: "Dr.", label: t("dropdowns.title.dr") },
									{ value: "Mr.", label: t("dropdowns.title.mr") },
									{ value: "Mrs.", label: t("dropdowns.title.mrs") }
								]}
							/>
						</Grid>
						<Grid item xs={12} md={4.5}>
							<Controller
								name="firstName"
								control={control}
								defaultValue=""
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<TextFieldInput
										inputProps={{
											maxLength: 100
										}}
										placeholder={t("signUpPageHCP.firstName.placeholder")}
										variant="outlined"
										value={value}
										onChange={e => {
											const v = e.target.value?.replace(/^\s*/gm, "");
											onChange(v);
										}}
										label={t("signUpPageHCP.firstName.label")}
										error={!!error}
										helperText={error ? error.message : null}
										type="text"
									/>
								)}
								rules={{
									required: true,
									minLength: {
										value: 2,
										message: t("dynamicForm.validation.minText")
									},
									maxLength: {
										value: 100,
										message: t("dynamicForm.validation.maxText")
									},
									pattern: {
										value: /^[^.:/]*$/,
										message: t("dynamicForm.validation.invalidCharacters")
									}
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4.5}>
							<Controller
								name="lastName"
								control={control}
								defaultValue=""
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<TextFieldInput
										inputProps={{
											maxLength: 100
										}}
										placeholder={t("signUpPageHCP.lastName.placeholder")}
										variant="outlined"
										value={value}
										onChange={e => {
											const v = e.target.value?.replace(/^\s*/gm, "");
											onChange(v);
										}}
										label={t("signUpPageHCP.lastName.label")}
										error={!!error}
										helperText={error ? error.message : null}
										type="text"
									/>
								)}
								rules={{
									required: true,
									minLength: {
										value: 2,
										message: t("dynamicForm.validation.minText")
									},
									maxLength: {
										value: 100,
										message: t("dynamicForm.validation.maxText")
									},
									pattern: {
										value: /^[^.:/]*$/,
										message: t("dynamicForm.validation.invalidCharacters")
									}
								}}
							/>
						</Grid>

						<Grid item xs={12}>
							<Controller
								name="primaryPlaceOfWork"
								control={control}
								defaultValue=""
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<TextFieldInput
										inputProps={{
											maxLength: 100
										}}
										placeholder={t("signUpPageHCP.primaryPlaceOfWork.placeholder")}
										variant="outlined"
										value={value}
										onChange={onChange}
										label={t("signUpPageHCP.primaryPlaceOfWork.label")}
										error={!!error}
										helperText={error ? error.message : null}
										type="text"
									/>
								)}
								rules={{
									required: true,
									minLength: {
										value: 2,
										message: t("dynamicForm.validation.minText")
									},
									maxLength: {
										value: 100,
										message: t("dynamicForm.validation.maxText")
									}
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<OutlinedSelectField
								required
								label={t("signUpPageHCP.profession.label")}
								type="select"
								placeholder={t("signUpPageHCP.profession.placeholder")}
								name="profession"
								control={control}
								defaultValue=""
								rules={{ required: true }}
								options={t("signUpPageHCP.profession.inputOptions", { returnObjects: true })}
							/>
						</Grid>
						{fields.map(field => (
							<Grid item key={field.name} xs={12}>
								<DynamicFields field={field} control={control} />
							</Grid>
						))}
						<Grid item xs={10} md={6}>
							<Controller
								control={control}
								name="recaptchaGoogle"
								rules={{ required: true }}
								render={({ field: { onChange }, fieldState: { error } }) => (
									<>
										<ReCaptcha
											sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
											hl={defaultLanguage}
											onChange={onChange}
										/>
										{error ? (
											<FormHelperText error sx={{ margin: 0 }}>
												{error.message}
											</FormHelperText>
										) : null}
									</>
								)}
							/>
						</Grid>
					</Grid>
				</FormWrapper>
			) : (
				<Box width="100%" height="50%" display="flex" alignItems="center" justifyContent="center">
					<CircularProgress size={100} />
				</Box>
			)}
		</>
	);
};

export default FormTwo;
