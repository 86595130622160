//TODO: remove this eslint rule
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { UserClaims } from "@okta/okta-auth-js";
import { countriesService } from "api/services/countries";
import { formsService } from "api/services/forms";
import { languagesService } from "api/services/languages";
import logger from "loggers";
import { Claims, Country, Language } from "models";

import { clearJwt, setJwt } from "../../jwt";

import { authService } from "../../services/auth";
import { oktaService } from "../../services/okta";
import { upsService } from "../../services/ups";

export interface CheckResponse {
	success: boolean;
}

export interface CheckVerifyResponse {
	success: boolean;
	hcp: boolean;
	referrer?: string;
}

// TODO: Create a get method instead of using find.
export const fetchCountries = async () => {
	const res: Country[] = await countriesService.find({}).catch(error => error);
	return res;
};

export const fetchLanguages = async () => {
	const res: Language[] = await languagesService.find({}).catch(error => error);
	return res;
};

// TODO: Use some toast notification to show errors.
export const signUpRequest = async data => {
	return await authService.signUp(data).catch(error => error);
};

export const checkIfUserSignedIn = (succ: () => void, err: () => void) => {
	oktaService
		.checkIfUseSignedIn()
		.then(r => {
			r ? succ() : err();
		})
		.catch(() => err());
};

export const signInRequest = (data, success: (s?: UserClaims<Claims>) => void) => {
	return oktaService
		.signIn(data)
		.then(r => {
			if (r?.tokens?.accessToken) {
				const {
					accessToken: { accessToken }
				} = r.tokens;
				setJwt(accessToken);
			}
			success(r.tokens.idToken?.claims as UserClaims<Claims>);
		})
		.catch(err => err);
};

export const continueSignIn = (success: (s?: UserClaims<Claims>) => void, error: () => void) => {
	oktaService
		.handleRedirect()
		.then(r => {
			if (r?.tokens?.accessToken) {
				const {
					accessToken: { accessToken }
				} = r.tokens;
				setJwt(accessToken);
			}
			success(r.tokens.idToken?.claims as UserClaims<Claims>);
		})
		.catch(err => {
			logger.error(err);
			error();
		});
};

export const signOutRequest = async () => {
	await oktaService.signOut();
};

export const clearLocalStorage = () => {
	clearJwt();
};

export const checkIfUserExists = async data => {
	const res: CheckResponse = await authService.checkUserExists(data).catch(error => error);
	return res;
};

export const checkIfCRMUserExists = async data => {
	return await authService.checkCRMUserExists(data).catch(error => error);
};

export const verifyEmail = async data => {
	const res: CheckVerifyResponse = await authService.verifyEmail(data).catch(error => error);
	return res;
};

export const getFormFieldsByCountry = async countryId => {
	return await formsService.getFormsByCountry(countryId).catch(error => {
		return error;
	});
};

export const resendEmail = async username => {
	return await authService.resendEmail(username).catch(e => e);
};

export const fetchProfessions = async () => {
	return await upsService.getProfessions().catch(e => e);
};
