import { useCallback, useEffect, useState } from "react";

import { Box, CircularProgress, FormHelperText, Grid } from "@mui/material";

import { getFormFieldsByCountry } from "api/features/Auth/authApi";
import TextFieldInput from "components/molecules/Inputs/TextFieldInput";
import OutlinedSelectField from "components/molecules/OutlinedSelectField";

import { IOptions } from "components/molecules/Select";

import { Profession, USProfession } from "models";
import { FormField } from "models/form.model";
import ReCaptcha from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { validateRequiredFields } from "utils/validateRequiredFields";

import ButtonFooter from "../../../molecules/ButtonFooter";
import FormWrapper from "../../../templates/FormWrapper";

export interface IFormTwo {
	title?: string;
	firstName: string;
	middleName?: string;
	lastName: string;
	profession?: string;
	specialty?: string;
	preferredLanguage?: string;
	countryCode?: string;
	address?: string;
	city?: string;
	state?: string;
	zipCode?: string;
	recaptchaGoogle: string;
}
const nonValidationFieldsList = ["codeRPPS"];
const usData: USProfession[] = [
	{ group: "Clinical Research", profession: [] },
	{ group: "Counselor", profession: [{ name: "Counselor" }, { name: "Genetic Counselor" }, { name: "Social Worker" }] },
	{ group: "Dietician", profession: [{ name: "Dietician" }, { name: "Nutritionist" }] },
	{
		group: "Mid-Level Practitioner",
		profession: [
			{ name: "Naturopathic Doctor" },
			{ name: "Physician Assistant" },
			{ name: "Nurse Practitioner" },
			{ name: "Nurse Midwife" },
			{ name: "Clinical Nurse Specialist" },
			{ name: "Psycologist" },
			{ name: "Optometrist" }
		]
	},
	{
		group: "Nurse",
		profession: [
			{ name: "Licensed Practical Nurse" },
			{ name: "Licensed Vocational Nurse" },
			{ name: "Registered Nurse" }
		]
	},
	{
		group: "Pharmacist",
		profession: [{ name: "Pharmacist" }, { name: "Pharmacist Intern" }, { name: "Pharmacist Technician" }]
	},
	{
		group: "Practioner",
		profession: [
			{ name: "Doctor of Osteopathy" },
			{ name: "Medical Doctor" },
			{ name: "Podiatrist" },
			{ name: "Dentist" },
			{ name: "Audiologist" },
			{ name: "Chiropractor" },
			{ name: "Veternarian" },
			{ name: "Fellow, Resident" }
		]
	},
	{ group: "Technician", profession: [] },
	{ group: "Therapist", profession: [] }
];

const DynamicFields = ({ field, control }) => {
	const { t } = useTranslation();
	switch (field.inputType) {
		case "select":
			return (
				<OutlinedSelectField
					required={field.required}
					label={t(`signUpPageHCP.${field.label}`)}
					type={field.inputType}
					placeholder={t(`signUpPageHCP.${field.placeholder}`)}
					name={field.name}
					control={control}
					defaultValue={field.defaultValue}
					rules={{ required: field.required }}
					options={t(`signUpPageHCP.${field.name}.inputOptions`, { returnObjects: true })}
				/>
			);
		case "text":
			return (
				<Controller
					name={field.name}
					control={control}
					defaultValue={field.defaultValue}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextFieldInput
							inputProps={{
								maxLength: 100
							}}
							placeholder={t(`signUpPageHCP.${field.placeholder}`)}
							variant="outlined"
							value={value}
							onChange={onChange}
							label={t(`signUpPageHCP.${field.label}`)}
							error={!!error}
							helperText={error ? error.message : null}
							type="text"
						/>
					)}
					rules={
						!nonValidationFieldsList.includes(field.name)
							? {
									required: field.required,
									minLength: {
										value: 2,
										message: t("dynamicForm.validation.minText")
									},
									maxLength: {
										value: 100,
										message: t("dynamicForm.validation.maxText")
									}
							  }
							: undefined
					}
				/>
			);
		default:
			return null;
	}
};
const FormTwoUS = ({ submitAllForms, setActiveStep, activeStep, isLoading }) => {
	const { t } = useTranslation();
	const { defaultLanguage } = useParams();
	const [fieldsLoading, setFieldsLoading] = useState(false);
	const [professionGroupOptions, setProfessionGroupOptions] = useState<IOptions[]>([]);
	const [professionOptions, setProfessionOptions] = useState<IOptions[]>([]);
	const [professionData, setProfessionData] = useState<Profession[]>([]);
	const [professionGroup, setProfessionGroup] = useState("Clinical Research");
	const { countryId } = useParams();
	const {
		control,
		handleSubmit,
		formState: { errors, isValid, isDirty }
	} = useForm({
		mode: "onChange",
		delayError: 1000,
		defaultValues: {
			title: "",
			firstName: "",
			middleName: "",
			lastName: "",
			recaptchaGoogle: "",
			primaryPlaceOfWork: ""
		}
	});
	const [fields, setFields] = useState<FormField[]>([]);
	const fetchFields = useCallback(async () => {
		setFieldsLoading(true);
		const apiResponse = await getFormFieldsByCountry(countryId);
		setFields(apiResponse.fields);
		setFieldsLoading(false);
	}, [countryId]);

	const getProfessionGroupOptions = useCallback(async () => {
		setProfessionGroupOptions(usData?.map(ups => ({ value: ups.group, label: ups.group })));
		return;
	}, []);

	useEffect(() => {
		getProfessionGroupOptions();
		fetchFields();
	}, [getProfessionGroupOptions, fetchFields]);

	const onSubmit = async (data: IFormTwo) => {
		data.profession = professionGroup;
		submitAllForms(data);
	};

	const handleChange = value => {
		// Do something with the selected value
		const selectedValue = value as string;

		setProfessionGroup(selectedValue);

		const selectedData = usData?.find(pgd => pgd.group === selectedValue);
		if (selectedData) {
			const data = selectedData.profession as unknown as Profession[];
			setProfessionData(data);
			setProfessionOptions(data.map(pd => ({ value: pd.name, label: pd.name })));
		} else {
			setProfessionData([]);
			setProfessionOptions([]);
		}
	};

	const isValidForm = validateRequiredFields(errors);
	return (
		<>
			{!fieldsLoading ? (
				<>
					<FormWrapper
						title={t("signUpPageHCP.title")}
						description={t("signUpPageHCP.subtitle")}
						footerBlock={
							<ButtonFooter
								isLoading={isLoading}
								primaryButtonText={t("buttons.signUp")}
								handlePrimaryButton={handleSubmit(onSubmit)}
								activeStep={activeStep}
								disablePrimaryButton={!isDirty || !isValid || isLoading}
								secondaryButton={true}
								handleSecondaryButton={() => setActiveStep(s => s - 1)}
							/>
						}
						onSubmit={handleSubmit(onSubmit)}
						errorSummary={!isValidForm ? t("signUpPageHCP.error") : ""}
					>
						<Grid container spacing={1}>
							<Grid item xs={12} md={1}>
								<OutlinedSelectField
									label={t("signUpPageHCP.nameTitle.label")}
									type="select"
									placeholder={t("signUpPageHCP.nameTitle.placeholder")}
									name="title"
									control={control}
									defaultValue=""
									options={[
										{ value: "Dr.", label: t("dropdowns.title.dr") },
										{ value: "Mr.", label: t("dropdowns.title.mr") },
										{ value: "Mrs.", label: t("dropdowns.title.mrs") }
									]}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<Controller
									name="firstName"
									control={control}
									defaultValue=""
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<TextFieldInput
											inputProps={{
												maxLength: 100
											}}
											placeholder={t("signUpPageHCP.firstName.placeholder")}
											variant="outlined"
											value={value}
											onChange={e => {
												const v = e.target.value?.replace(/^\s*/gm, "");
												onChange(v);
											}}
											label={t("signUpPageHCP.firstName.label")}
											error={!!error}
											helperText={error ? error.message : null}
											type="text"
										/>
									)}
									rules={{
										required: true,
										minLength: {
											value: 2,
											message: t("dynamicForm.validation.minText")
										},
										maxLength: {
											value: 100,
											message: t("dynamicForm.validation.maxText")
										},
										pattern: {
											value: /^[^.:/]*$/,
											message: t("dynamicForm.validation.invalidCharacters")
										}
									}}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<Controller
									name="middleName"
									control={control}
									defaultValue=""
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<TextFieldInput
											inputProps={{
												maxLength: 100
											}}
											placeholder={t("signUpPageHCP.middleName.placeholder")}
											variant="outlined"
											value={value}
											onChange={e => {
												const v = e.target.value?.replace(/^\s*/gm, "");
												onChange(v);
											}}
											label={t("signUpPageHCP.middleName.label")}
											error={!!error}
											helperText={error ? error.message : null}
											type="text"
										/>
									)}
									rules={{
										required: false,
										minLength: {
											value: 2,
											message: t("dynamicForm.validation.minText")
										},
										maxLength: {
											value: 100,
											message: t("dynamicForm.validation.maxText")
										},
										pattern: {
											value: /^[^.:/]*$/,
											message: t("dynamicForm.validation.invalidCharacters")
										}
									}}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<Controller
									name="lastName"
									control={control}
									defaultValue=""
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<TextFieldInput
											inputProps={{
												maxLength: 100
											}}
											placeholder={t("signUpPageHCP.lastName.placeholder")}
											variant="outlined"
											value={value}
											onChange={e => {
												const v = e.target.value?.replace(/^\s*/gm, "");
												onChange(v);
											}}
											label={t("signUpPageHCP.lastName.label")}
											error={!!error}
											helperText={error ? error.message : null}
											type="text"
										/>
									)}
									rules={{
										required: true,
										minLength: {
											value: 2,
											message: t("dynamicForm.validation.minText")
										},
										maxLength: {
											value: 100,
											message: t("dynamicForm.validation.maxText")
										},
										pattern: {
											value: /^[^.:/]*$/,
											message: t("dynamicForm.validation.invalidCharacters")
										}
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<OutlinedSelectField
									label={t("account.form.fields.professionGroup.label")}
									type="select"
									required
									placeholder={t("account.form.fields.professionGroup.placeholder")}
									name="profession"
									control={control}
									defaultValue="Clinical Research"
									value={professionGroup}
									options={professionGroupOptions}
									onChange={handleChange}
								/>
							</Grid>
							{professionData.length > 0 && (
								<Grid item xs={12}>
									<OutlinedSelectField
										label={t("account.form.fields.profession.label")}
										type="select"
										placeholder={t("account.form.fields.profession.placeholder")}
										name="specialty"
										control={control}
										defaultValue=""
										options={professionOptions}
									/>
								</Grid>
							)}
							{fields.map(field => (
								<Grid item key={field.name} xs={12}>
									<DynamicFields field={field} control={control} />
								</Grid>
							))}
							<Grid item xs={10} md={6}>
								<Controller
									control={control}
									name="recaptchaGoogle"
									rules={{ required: true }}
									render={({ field: { onChange }, fieldState: { error } }) => (
										<>
											<ReCaptcha
												sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
												hl={defaultLanguage}
												onChange={onChange}
											/>
											{error ? (
												<FormHelperText error sx={{ margin: 0 }}>
													{error.message}
												</FormHelperText>
											) : null}
										</>
									)}
								/>
							</Grid>
						</Grid>
					</FormWrapper>
				</>
			) : (
				<Box width="100%" height="50%" display="flex" alignItems="center" justifyContent="center">
					<CircularProgress size={100} />
				</Box>
			)}
		</>
	);
};
export default FormTwoUS;
