import { useEffect, useState } from "react";

import { Box, Grid, Typography, styled } from "@mui/material";
import { CheckVerifyResponse, verifyEmail } from "api/features/Auth/authApi";
import { Cross } from "assets";
import PrimaryButton from "components/molecules/Buttons/PrimaryButton";

import PageWithLogo from "components/templates/PageWithLogo";
import usePrefix from "hooks/usePrefix";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { Link, useParams } from "react-router-dom";

import { LoadingAnimationData, SuccessAnimationData } from "assets/animations";

const StyledContainer = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-self: center;
	justify-content: center;
	align-items: center;
`;

const TextBody = (data: { heading: string; description: string }) => {
	const { heading, description } = data;
	return (
		<>
			<Grid item xs={12} display="flex" mt={4}>
				<Typography variant="h1Headline" color="tertiary.main" align="center">
					{heading}
				</Typography>
			</Grid>
			<Grid item xs={12} mt={1}>
				<Typography variant="textMedium" color="customShades.grey1">
					{description}
				</Typography>
			</Grid>
		</>
	);
};

const HCPTextBody = (data: { heading: string; line1: string; line2: string; line3: string }) => {
	const { heading, line1, line2, line3 } = data;
	return (
		<>
			<Grid item xs={12} display="flex" mt={4}>
				<Typography variant="h1Headline" color="tertiary.main" align="center">
					{heading}
				</Typography>
			</Grid>
			<Grid item xs={12} mt={1}>
				<Typography variant="textLead" color="tertiary.main" align="center">
					{line1}
				</Typography>
			</Grid>
			<Grid item xs={12} mt={1}>
				<Typography variant="textLead" color="tertiary.main" align="center">
					{line2}
				</Typography>
			</Grid>
			<Grid item xs={12} mt={1}>
				<Typography variant="textLead" color="tertiary.main" align="center">
					{line3}
				</Typography>
			</Grid>
		</>
	);
};

const Success = ({ input, hcp }) => {
	const { t } = useTranslation();
	const { linkPrefix } = usePrefix();
	let useInternal = false;
	let ref = input + "";
	if (ref) {
		const isInternalReferrer = ref.toLowerCase().includes("accounts.takeda.com");
		if (isInternalReferrer) {
			ref = linkPrefix + "/signIn";
			useInternal = true;
		}
	} else {
		ref = linkPrefix + "/signIn";
		useInternal = true;
	}
	return (
		<>
			<Lottie
				options={{
					loop: false,
					autoplay: true,
					animationData: SuccessAnimationData,
					rendererSettings: {
						preserveAspectRatio: "xMidYMid slice"
					}
				}}
				height={212}
				width={217.8}
			/>
			<Grid container spacing={1} direction="column" alignItems="center" justifyContent="center" maxWidth="100%">
				{hcp ? (
					<HCPTextBody
						heading={t("emailActivated.headingHCP")}
						line1={t("emailActivated.subtitleHCP1")}
						line2={t("emailActivated.subtitleHCP2")}
						line3={t("emailActivated.subtitleHCP3")}
					/>
				) : (
					<TextBody heading={t("emailActivated.heading")} description={t("emailActivated.subtitle1")} />
				)}
				<Grid item xs={12} mt={5}>
					{useInternal ? (
						<Link to={ref}>
							<PrimaryButton variant="contained">{t("buttons.continue")}</PrimaryButton>
						</Link>
					) : (
						<a href={ref} target="_blank" rel="noreferrer">
							<PrimaryButton variant="contained">{t("buttons.continue")}</PrimaryButton>
						</a>
					)}
				</Grid>
			</Grid>
		</>
	);
};
const Failure = () => {
	const { t } = useTranslation();
	return (
		<>
			<Cross height={164} width={164} />
			<Grid container direction="column" alignItems="center" justifyContent="center" maxWidth="100%">
				<TextBody heading={t("emailActivated.heading1")} description={t("emailActivated.subtitle3")} />
				<Grid item xs={12} mt={5}>
					<Link to="/self-registration">
						<PrimaryButton variant="contained">{t("emailActivated.BackToSignUp")}</PrimaryButton>
					</Link>
				</Grid>
			</Grid>
		</>
	);
};
const Loading = () => {
	const { t } = useTranslation();
	return (
		<>
			<Lottie
				options={{
					loop: true,
					autoplay: true,
					animationData: LoadingAnimationData,
					rendererSettings: {
						preserveAspectRatio: "xMidYMid slice"
					}
				}}
				height={240}
				width={252}
			/>
			<Grid container xs={6} spacing={2} direction="column" alignItems="center" justifyContent="center" maxWidth="100%">
				<TextBody heading={t("emailActivated.heading2")} description={t("emailActivated.subtitle4")} />
			</Grid>
		</>
	);
};

const EmailActivated = () => {
	const { code } = useParams();
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(true);
	const [referrer, setReferrer] = useState<string>("");
	const [hcp, setHCP] = useState(false);
	useEffect(() => {
		const checkVerification = async () => {
			const response: CheckVerifyResponse = await verifyEmail(code);
			setLoading(false);
			if (response.success) {
				setSuccess(true);
			}
			if (response.referrer) {
				//window.setTimeout(function () {
				//	window.location.replace(response.referrer!);
				//}, 5000);
				setReferrer(response.referrer);
			}
			setHCP(response.hcp);
		};
		checkVerification();
	}, [code]);

	return (
		<PageWithLogo>
			<StyledContainer padding={3}>
				{loading ? <Loading /> : success ? <Success input={referrer} hcp={hcp} /> : <Failure />}
			</StyledContainer>
		</PageWithLogo>
	);
};

export default EmailActivated;
