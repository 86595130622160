import InfoPageWrapper from "components/templates/InfoPageWrapper";

import DE from "./translations/de.md";
import EL from "./translations/el.md";
import EN from "./translations/en.md";
import ES from "./translations/es.md";
import FR from "./translations/fr.md";
import IT from "./translations/it.md";
import PL from "./translations/pl.md";
import RO from "./translations/ro.md";

const PrivacyPolicy = () => {
	const dict = {
		el: EL,
		en: EN,
		es: ES,
		fr: FR,
		it: IT,
		de: DE,
		ro: RO,
		pl: PL
	};

	return <InfoPageWrapper dict={dict}>&nbsp;</InfoPageWrapper>;
};
export default PrivacyPolicy;
